.MainApp{
  background: #191919;
  width: 100vw;
  height: 100vh;
  background-image: url('../assets/bg.jpeg');
  background-size: 100% auto;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 20px;
  font-family: "Josefin Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  color:#CCC;
  position: fixed;
  overflow-y: scroll;
}
.logo{
  /* width: 200px; */
}
.logo img{
  width: 100%;
  height: auto;
}

/* Navbar içindeki yazılar için özel stil (isteğe bağlı) */
.navbar-nav .nav-link {
  color: #CCC !important;
  font-size: 20px;
}

.navbar-brand {
  color: #CCC !important;
}

.box {
  background-color: #44444417;
  min-height: 200px;
  border-radius: 15px;
  backdrop-filter: blur(8px);
}

.cardstyle1{
  background-color: transparent;
  color: #CCC !important;
  border: 0px;
  margin-top: 10px;
}

.cardstyle1 .list-group-item{
  background-color: rgba(189, 189, 189, 0.27);
  color:#FFF;
  border-radius: 5px;
  margin-top:8px;
  cursor: pointer;
  border-bottom-width: 3px;
}
.list-group-item:hover{
  background-color: #ffd53b;
  color:#000 !important;
  border-color:#614c00;
}

.site-slider{
  display: flex;
  text-align: center;
}
.slider-card{
  margin-left: 10px;
}
.slider-card img{
min-width: 120px;
object-fit: cover;
height: 120px;
border-radius: 20px;
 }

.slider-card h4{
 margin-top: 10px;
}

.contact{
  display: flex;
  justify-content: center;
  align-items: center;
}

.sites img{
  width: 100%;
  object-fit: cover;
  border-radius: 20%;
  height: 100%;

}
.sites{
  margin-bottom: 20px;
}

.sites .siteLogoContainer{
  background-color: #111;
  /* height: 120px;
  width: 120px; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.sitesContainer{
}